import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	loading: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
}));

const Loading = (props) => {
	const classes = useStyles();
	return <CircularProgress className={classes.loading} color='secondary' />;
};

export default Loading;
