import React, { Component } from 'react';
import { websiteUrl } from '../config/settings';

import { LOAD_STATE } from '../core/constants';

import { resParseGeneral, reqLandingTarget } from '../api/databaseAPI';

import LoadingPage from '../components/UI/LoadingPage';

class LandingPage extends Component {
	state = {
		loadState: LOAD_STATE.INIT,
		type: null,
		data: null,
	};
	componentDidMount() {
		if (this.props.match.params.target) {
			reqLandingTarget(this.props.match.params.target)
				.then((response) => {
					const target = resParseGeneral(response);
					this.setState({
						type: target.type,
						data: target.data,
						loadState: LOAD_STATE.SUCCESS,
					});
				})
				.catch((error) => {
					this.setState({
						loadState: LOAD_STATE.FAIL,
					});
				});
		} else {
			this.setState({ loadState: LOAD_STATE.FAIL });
		}
	}
	render() {
		const { data, type, loadState } = this.state;
		console.log(loadState, type, data);
		if (LOAD_STATE.SUCCESS === loadState) {
			if (type === 'card') {
				window.location.href =
					websiteUrl + `@${data.username}/${data.cardname}`;
			} else if (type === 'profile') {
				window.location.href = websiteUrl + `@${data.username}`;
			}
		} else if (LOAD_STATE.FAIL === loadState) {
			window.location.href = websiteUrl + '404';
		}
		return (
			<>
				<LoadingPage />
			</>
		);
	}
}

export default LandingPage;
