import { Route, Switch } from 'react-router-dom';
import LandingPage from './containers/LandingPage';
import { websiteUrl } from './config/settings';

function App() {
	return (
		<div className='App'>
			<Switch>
				<Route path='/@:target' exact component={LandingPage} />
				<Route
					component={() => {
						window.location.href = websiteUrl;
						return null;
					}}
				/>
			</Switch>
		</div>
	);
}

export default App;
