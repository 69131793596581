import axios from '../core/axios/axios';
import appInfo from '../config/appInfo';
axios.defaults.headers.common['SWVersion'] = appInfo.swVersion;

export const resParseGeneral = (res) => {
	return res.data;
};

export const reqLandingTarget = (target) => {
	return axios.get(`/url/${target}`);
};
